<template>
  <div class="WJgroupHonor">
    <headert></headert>
    <el-row class="content ">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/concept.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class="  " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">

              <p class="WJgroup-title">  {{typeInfo.typename}}</p>
            </div>
          </div>
          <div class="WJgroup_teamTips flex justify_center ">
            <span class="WJgroup_teamTl">“</span>
            <div>
              <p style="white-space: pre-line">  {{typeInfo.idea}}
              </p>
<!--              <p>立志提高集团上下人才工作水平，打造一支有梦想、有激情、有责任、有情怀、有品位的干部队伍。</p>-->
            </div>
            <span class="WJgroup_teamTr">”</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="talentConceptBox " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="talentConceptTitle">
            {{typeInfo.typename}}
          </div>
          <div class="talentConceptFont">
           {{typeInfo.description}}
          </div>
        </el-col>
      </el-row>

      <el-row class="talentMoreBox " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb150">
            <ul class="talentMore flex justify_center">
              <li v-for="(item,index) in newsList" :key="index" v-if="index<3">
                <img :src="item.litpic" alt="">
                <h6>{{item.title}}</h6>
                <p class="elfive">{{item.describe}}</p>
              </li>
<!--            </ul>-->
<!--            <ul class="talentMore flex justify_center">-->
              <li v-for="(item,index) in newsList" :key="index" v-if="index>=3&&index<6">
                <img :src="item.litpic" alt="">
                <h6>{{item.title}}</h6>
                <p class="elfive">{{item.describe}}</p>
              </li>
            </ul>

          </div>

        </el-col>
      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/personneltalentConcept',
        menu: {
          name: '人才窗口',
          path: '/personneltalentConcept',
          list: [{
            name: this.$t('menu.talented'),
            path: '/personneltalentConcept',
          }, {
            name: this.$t('menu.school'),
            path: '/personnelCadreSchool',
          }, {
            name:  this.$t('menu.joinUs'),
            path: '/personnelJoinUs',
          }, ]
        },
        breadcrumb: [{
          name: this.$t('menu.recruit'),
          path: '/personneltalentConcept',
        }, {
          name: this.$t('menu.talented'),
          path: '/personneltalentConcept',
        }],

        teamIndex: 1,

        typeid:103,
        newsList:[],
        typeInfo:{}
      };
    },
    computed: {},
    created() {
      this.getData();
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        let condition=`ekey=1&typeid=${this.typeid}&limit=6&addfields=describe,litpic_en,title_en,describe_en`;
        this.jobList=[];
        this.$request.post(api,{
          apiArclist_1:condition,
          apiType_1:`ekey=1&typeid=${this.typeid}&addfields=title_en,idea,litpic_en,title_en,describe_en`
        }).then(res=>{
          this.typeInfo=this.$utils.translate(res.apiArclist[1].arctype)

          this.newsList =this.$utils.translate(res.apiArclist[1].data)
        })
      },
      choseTeam(e) {
        this.teamIndex = e
      },
      setActiveItem(index) {
        this.$refs.Carousel.setActiveItem(index)
      },
      carouselChange(e) {

        this.yearIndex = e
      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .WJgroup_teamTips div {
    // width: 81%;
  }

  .WJgroup_teamTips p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    color: #8F6B4B;
    margin-bottom: 20px;

  }

  .WJgroup_teamTips span {
    font-family: 'PangMenZhengDao';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 73px;
    /* identical to box height */
    color: #A4825F;
  }

  .WJgroup_teamTl {
    margin-bottom: 102px;
    margin-right: 24px;
  }

  .WJgroup_teamTr {
    margin-left: 24px;
    margin-bottom: -140px;
  }

  .talentConceptBox {
    background: url('../../assets/image/Industry/talentConcept.png') no-repeat;
    background-size: 100%;
    margin-top: 130px;
  }

  .talentConceptTitle {
    padding: 64px 0;
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
  }

  .talentConceptTitle::before {
    content: "";
    width: 32px;
    height: 3px;
    background: #FFFFFF;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -16px;
  }

  .talentConceptFont {
    width: 98%;
    margin: 0 auto;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    padding-bottom: 70px;
  }

  .talentMoreBox {
    background: #F6F6F6;
  }

  .talentMore {
    margin-bottom: 38px;
    flex-wrap: wrap;
  }

  // .talentMore li span {
  //   width: 0;
  //   height: 4px;
  //   position: absolute;
  //   top: 0;
  //   bottom: ;
  //   transition: 1s;
  //   background-color: #0D84D8;
  // }

  // .talentMore li:hover span {
  //   width: 100%;
  // }

  .talentMore li {
    width: 28%;
    height: 394px;
    background: #FFFFFF;
    padding: 40px 30px;
    box-sizing: border-box;
    text-align: center;
    margin: 0 14px;
    position: relative;
  }

  .talentMore li::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 4px;
    background: #0D84D8;
    transition: 1s;
  }
  .talentMore li:hover::before{
    width: 100%;
  }
  .talentMore li:nth-child(2)::before {
    background: #EA543C;
  }

  .talentMore li:nth-child(3)::before {
    background: #F28D01;
  }

  .talentMore li img {
    width: 59px;
    height: 59px;
  }

  .talentMore li h6 {
    // font-family: 'OPPOSans-M';
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #1F2D39;
    margin-top: 24px;
    margin-bottom: 27px;
  }

  .talentMore li p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  white-space: pre-line;
  }

  @media screen and (min-width:320px) and (max-width:767px) {
   .WJgroupHonor .WJgroup_teamTips p{
    font-size: 39px;
     line-height: 52px;
    }
    .talentConceptBox{
      background-size: cover;
      .talentConceptTitle{
        font-size: 39px;
        padding: 30px;
        &:before{
          bottom: 10px;
        }
      }
      .talentConceptFont{
        font-size: 34px;
        line-height: 44px;
      }
    }
    .WJgroupHonor .talentMore li{
      width: 46%;
      margin-bottom: 30px;
      flex-shrink: 0;
      height: 420px;
      h6{
         font-size: 34px;
      }
      p{
        font-size: 30px;
        line-height: 44px;
      }
    }

    .WJgroup_teamTl {
      margin-bottom: 402px;
      margin-right: 24px;
    }

    .WJgroup_teamTr {
      margin-left: 24px;
      margin-bottom: -340px;
    }

  }
</style>
